import React from 'react';
import './QuienesSomos.css'; // Asegúrate de estilizar el archivo

function QuienesSomos() {
  return (
    <div className="quienes-somos-container">
            <section className="quienes-somos">
        <h1>Quiénes Somos</h1>
        <p>
          Somos una empresa joven y dinámica especializada en brindar soluciones integrales de limpieza para condominios, edificios, oficinas, y espacios comerciales. Nos apasiona ofrecer servicios de aseo eficientes, personalizados, y amigables con el medio ambiente, siempre enfocados en superar las expectativas de nuestros clientes.
        </p>
        <p>
          En FYP ASEO SPA creemos que un entorno limpio no solo es esencial para la salud, sino también para el bienestar y la productividad. Por eso combinamos tecnología innovadora, productos ecológicos, y un equipo comprometido para asegurar que cada rincón quede impecable y libre de preocupaciones.
        </p>
        <p>
          Nuestro objetivo es ser más que un proveedor de servicios; queremos ser tu aliado confiable en la creación de espacios limpios, seguros, y acogedores mientras promovemos la sostenibilidad y el cuidado del planeta.
        </p>
      </section>
      <div className="mision-vision-container">
        <section className="mision">
          <h2>Misión</h2>
          <p>
            Brindar servicios de aseo de alta calidad, adaptados a las necesidades específicas de cada cliente. Nos esforzamos por crear espacios limpios y saludables, a la vez que fomentamos el desarrollo de nuestro equipo, la satisfacción del cliente, y la innovación constante, contribuyendo al cuidado del medio ambiente.
          </p>
        </section>
        <section className="vision">
          <h2>Visión</h2>
          <p>
            Convertirnos en la empresa de aseo referente en innovación y sostenibilidad, reconocida por transformar espacios a través de soluciones de limpieza eficientes y responsables y por nuestro compromiso con la mejora continua, la satisfacción del cliente y el cuidado del planeta.
          </p>
        </section>
      </div>


    </div>
  );
}

export default QuienesSomos;
