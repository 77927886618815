import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faWhatsapp, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faBars } from '@fortawesome/free-solid-svg-icons'; // Agregamos el ícono de menú

function Header() {
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Controla si el menú está abierto o cerrado
  let lastScroll = 0;

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll > lastScroll) {
        setIsScrollingDown(true); // Oculta el header cuando haces scroll hacia abajo
      } else {
        setIsScrollingDown(false); // Muestra el header cuando haces scroll hacia arriba
      }
      lastScroll = currentScroll;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Alterna el estado del menú al hacer clic en el ícono de menú
  };

  return (
    <header className={isScrollingDown ? 'hide-header' : 'show-header'}>
      <div className="header-content">
        <div className="left-content">
          <img
            src={process.env.PUBLIC_URL + '/imagen/FYP.svg'}
            alt="Logo de la empresa"
            className="logo"
          />
          {/* Ícono de menú para dispositivos móviles */}
          <div className="menu-icon" onClick={toggleMenu}>
            <FontAwesomeIcon icon={faBars} />
          </div>

          {/* Menú de navegación */}
          <nav className={`menu ${isMenuOpen ? 'menu-open' : ''}`}>
            <ul>
              <li><Link to="/" onClick={toggleMenu}>Inicio</Link></li>
              <li><Link to="/quienes-somos" onClick={toggleMenu}>Quiénes somos</Link></li>
              <li><Link to="/contacto" onClick={toggleMenu}>Contacto</Link></li>
            </ul>
          </nav>
        </div>

        <div className="right-content">
          <div className="social-icons">
            <a href="https://www.instagram.com/fyp_seguridad/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="https://wa.me/56993303890" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a href="mailto:contacto@fypaseo.cl">
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;