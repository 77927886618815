import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBroom, faSprayCan, faSearch } from '@fortawesome/free-solid-svg-icons'; // Importa íconos relacionados con limpieza
import './Inicio.css';

function Inicio() {
  return (
    <div style={{ backgroundColor: '#f2f7fa', padding: '40px', textAlign: 'center' }}>
      {/* Sección de Bienvenida */}
      <div style={{ marginBottom: '60px' }}>
        <h1 style={{ fontSize: '40px', color: '#4CAF50' }}>Bienvenidos a FYP Aseo SPA</h1>
        <p style={{ fontSize: '18px', color: '#555', maxWidth: '800px', margin: '0 auto' }}>
          Somos una empresa dedicada a limpieza integral para oficinas, edificios y espacios comerciales.
        </p>
      </div>

      {/* Sección de Servicios */}
      <div className="servicios-container">
        <h2 style={{ color: '#4CAF50', marginBottom: '40px' }}>Nuestros Servicios</h2>
        <div className="servicios-grid">
          <div className="servicio-card">
            <h3>Limpieza de Oficinas</h3>
            <p>Ofrecemos servicios de limpieza detallada y eficaz para mantener su espacio de trabajo impecable.</p>
          </div>
          <div className="servicio-card">
            <h3>Limpieza de Edificios</h3>
            <p>Mantenemos sus edificios relucientes con productos ecológicos y personal capacitado.</p>
          </div>
          <div className="servicio-card">
            <h3>Limpieza Comercial</h3>
            <p>Espacios comerciales siempre listos para recibir a sus clientes, con una limpieza de calidad profesional.</p>
          </div>
        </div>
      </div>

      {/* Sección del Proceso de Trabajo con íconos */}
      <div className="proceso-trabajo-container">
        <h2>Nuestro Proceso de Trabajo</h2>
        <div className="proceso-grid">
          <div className="cuadro-proceso">
            <div className="cuadro-icono">
              <FontAwesomeIcon icon={faBroom} size="2x" /> {/* Ícono de escoba */}
            </div>
            <h3>Agendar Visita</h3>
            <p>Organizamos una visita para evaluar tus necesidades de limpieza.</p>
          </div>

          <div className="cuadro-proceso">
            <div className="cuadro-icono">
              <FontAwesomeIcon icon={faSearch} size="2x" /> {/* Ícono de evaluación */}
            </div>
            <h3>Evaluación y Definición</h3>
            <p>Definimos el alcance del servicio según tus requerimientos.</p>
          </div>

          <div className="cuadro-proceso">
            <div className="cuadro-icono">
              <FontAwesomeIcon icon={faSprayCan} size="2x" /> {/* Ícono de spray */}
            </div>
            <h3>Realización del Servicio</h3>
            <p>Implementamos el servicio de limpieza con los mejores estándares.</p>
          </div>
        </div>
      </div>

      {/* Sección de Contacto */}
      <div className="contacto-container">
        <p>
          ¿Necesita asesoramiento sobre limpieza? ¡Consulte con nuestros expertos de manera gratuita!
        </p>
        <a href="/contacto" className="boton-contacto">Contactar</a>
      </div>
    </div>
  );
}

export default Inicio;
