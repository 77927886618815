import React, { useState } from 'react';
import './Contacto.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faComments, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'; // Importa íconos de teléfono y correo

function Contacto() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name && email && message) {
      fetch('https://fypaseo.cl/sendEmail.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          name: name,
          email: email,
          message: message
        })
      })
        .then((response) => response.text())
        .then((result) => {
          if (result === "success") {
            setSubmitStatus('success');
            alert('Mensaje enviado con éxito');
            setName('');
            setEmail('');
            setMessage('');
          } else {
            setSubmitStatus('error');
            alert('Error al enviar el mensaje');
          }
        })
        .catch((error) => {
          setSubmitStatus('error');
          alert('Error al enviar el mensaje');
        });
    } else {
      setSubmitStatus('error');
      alert('Por favor, rellena todos los campos.');
    }
  };

  return (
    <div className="contacto-page">
      <div className="contacto-container">
        <h1>Contáctanos</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Nombre:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              placeholder="Ingresa tu nombre"
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Ingresa tu email"
            />
          </div>
          <div className="form-group">
            <label>Mensaje:</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              placeholder="Escribe tu mensaje"
            />
          </div>
          <button
            type="submit"
            className={`btn btn-submit ${submitStatus === 'success' ? 'btn-success' : ''} ${submitStatus === 'error' ? 'btn-error' : ''}`}
          >
            Enviar
          </button>
        </form>
      </div>

      {/* Sección de Conversemos */}
      <div className="conversemos-container">
        <div className="conversemos-box">
          <FontAwesomeIcon icon={faComments} size="3x" />
          <h2>Conversemos</h2>
          <p>
            <FontAwesomeIcon icon={faPhone} /> +56993303890
            <br />
            <FontAwesomeIcon icon={faEnvelope} /> Contacto@fypaseo.cl
          </p>
        </div>
        <div className="conversemos-box">
          <FontAwesomeIcon icon={faComments} size="3x" />
          <h2>Conversemos</h2>
          <p>Yasmiris Parra 

            <br/>Gerente de Administración y Recurso Humanos
            <br />
            <FontAwesomeIcon icon={faPhone} /> +56949137923
            <br />
            <FontAwesomeIcon icon={faEnvelope} /> Yasmiris.parra@fypaseo.cl
          </p>
        </div>
        <div className="conversemos-box">
          <FontAwesomeIcon icon={faComments} size="3x" />
          <h2>Conversemos</h2>
          <p>Jimmy Fuentes 
             <br/>Gerente Operaciones
            <br />
            <FontAwesomeIcon icon={faPhone} /> +56920286969
            <br />
            <FontAwesomeIcon icon={faEnvelope} /> Jimmy.fuentes@fypaseo.cl
          </p>
        </div>
      </div>
    </div>
  );
}

export default Contacto;
